<template>
  <div class="page">
    <PageImage :title="$t('title.stroj')" :img="`P7220683.jpg`"/>
    <div class="content">
      <div class="page-title">
        <h1>{{ $t('stroj.title') }}</h1>
        <div class="border-short"></div>
      </div>

      <div class="page-content" v-in-viewport.once='{ margin: "-150px 0px" }'>
        <p class="mate-text" v-html="$t('stroj.text')"></p>
      </div>
      <div>
        <div class="prod-content-3">
          <div class="grid grid-rows-1 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2">
            <div class="col-span-1">
              <img class="mate2-img" src="/P7220686.JPG"  alt=""/>
            </div>
            <div class="col-span-1 text-center" v-in-viewport.once="{ margin: '-150px 0px' }">
              <img class="mate2-img" src="/P7220701.JPG"  alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageImage from '@/components/PageImage.vue'

export default {
  name: 'Home',
  components: {
    PageImage
  }
}
</script>
