<template>
  <div class="page">
    <PageImage :title="$t('title.home')" :img="`o-nama-ulaz.jpg`" />
    <div class="content">
      <div class="eu-banner grid grid-rows-1 grid grid-cols-3 mb-20">
        <p></p>
        <img src="@/assets/eu-banner.png" alt="" style="width: 100%"/>
        <p></p>
      </div>

      <div>
        <div class="prod-content-3">
          <div class="grid grid-rows-1 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2">
            <div class="col-span-1">
              <img class="mate2-img" src="/P7220608.JPG"  alt=""/>
            </div>
            <div class="col-span-1 text-center" v-in-viewport.once="{ margin: '-150px 0px' }">
              <img class="mate2-img" src="/P7220686.JPG"  alt=""/>
            </div>
          </div>
          <div class="grid grid-rows-1 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2">
            <div class="col-span-1">
              <img class="mate2-img" src="/P7220559.JPG"  alt=""/>
            </div>
            <div class="col-span-1 text-center" v-in-viewport.once="{ margin: '-150px 0px' }">
              <img class="mate2-img" src="/P7220618.JPG"  alt=""/>
            </div>
          </div>
        </div>
      </div>

      <div
          class="
          about-main
          grid grid-rows-1 grid-cols-1
          lg:grid-rows-1 lg:grid-cols-2
        "
      >
        <div
            class="col-span-1 text-center center-vertical"
            v-in-viewport.once="{ margin: '-150px 0px' }"
        >
          <h1 class="home-text">{{ $t("home.about-text") }}</h1>
          <button class="home-button">
            <router-link to="/about">{{ $t("home.button") }}</router-link>
          </button>
        </div>
        <div class="col-span-1 about-img">
          <img src="/home-about.jpg" />
        </div>
      </div>

    </div>
    <div class="prod-content">
      <div
        class="
          about-main
          grid grid-rows-1 grid-cols-1
          lg:grid-rows-1 lg:grid-cols-2
        "
      >
        <div class="col-span-1 about-img pad-40">
          <img src="/prod.jpg" />
        </div>
        <div
          class="col-span-1 text-center center-vertical pad-40"
          v-in-viewport.once="{ margin: '-150px 0px' }"
        >
          <h1 class="home-text">{{ $t("home.products") }}</h1>
          <button class="home-button custom-button">
            <router-link to="/catalog">{{ $t("home.button") }}</router-link>
          </button>
        </div>
      </div>
    </div>
    <div class="machining-main">
      <div class="background-fade">
        <div
            class="text-center center-vertical white padding-20"
            v-in-viewport.once="{ margin: '-150px 0px' }"
        >
          <h1 class="home-text">{{ $t("home.machining-text") }}</h1>
          <button class="home-button white">
            <router-link to="/machining">{{ $t("home.button") }}</router-link>
          </button>
        </div>
      </div>
    </div>
    <div class="content mt-20">
      <div>
        <div
          class="text-center center-vertical"
          v-in-viewport.once="{ margin: '-150px 0px' }"
        >
          <h1 class="home-text eu-text">{{ $t("home.eu") }}</h1>
        </div>
        <div class="eu-img grid grid-rows-1 grid-cols-3">
          <img class="col-span-1" src="/eu1.jpg" />
          <img class="col-span-1" src="/eu2.jpg" />
          <img class="col-span-1" src="/eu3.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.center-vertical {
  margin: auto;
  opacity: 0;
  transition: opacity 2s;
}
.center-vertical.in-viewport {
  opacity: 1;
}
.home-text {
  font-size: 30px;
  padding: 0 30px;
}
.about-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.mate2-img {
  padding: 10px;
  height: 800px;
  width: 100%;
  object-fit: cover;
}
.home-button {
  font-size: 25px;
  color: black;
  padding: 8px 12px;
  margin: 30px 0 0;
  border: 2px solid #0093dd;
  background: 0;
  color: #5a5a6e;
  cursor: pointer;
  transition: all 0.3s;
}
.home-button:hover {
  background: #0093dd;
  color: #fff;
}
.machining-main {
  height: 580px;
  background-size: cover;
  background-image: url("/home-machining.jpg");
}
.background-fade {
  display: flex;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.padding-20 {
  padding: 0 20px;
}
.white {
  color: white;
}
.prod-content {
  background-color: #0193de;
  padding: 30px;
  color: white;
}
.pad-40 {
  padding: 40px;
}
.custom-button {
  color: white;
  border-color: white;
}
.custom-button:hover {
  background-color: black;
  border-color: black;
}
.eu-text {
  margin-bottom: 30px;
}
.eu-img {
  height: 200px;
}
.eu-banner {
  width: 100%;
  height: 100%;
  grid-template-columns: 15% 70% 15%
}
.eu-img img {
  max-height: 100%;
  margin: auto;
}
@media only screen and (max-width: 1023px) {
  .about-img {
    display: none;
  }
  .about-main {
    height: auto;
  }
  .machining-main {
    height: 500px;
  }
}
@media only screen and (max-width: 675px) {
  .eu-banner {
    margin-bottom: 40px;
  }
  .home-text {
    font-size: 24px;
    margin-top: 25px;
  }
  .home-button {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .mate2-img {
    object-fit: contain;
    height: 100%;
  }
  .eu-text {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 500px) {
  .home-text {
    font-size: 20px;
  }
  .home-button {
    font-size: 17px;
  }
}
</style>

<script>
import PageImage from "@/components/PageImage.vue";

export default {
  name: "Home",
  components: {
    PageImage,
  },
};
</script>
