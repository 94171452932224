<template>
  <div class="page">
    <PageImage :title="$t('title.mate')" :img="`P7220568.JPG`"/>
    <div class="content mt-10">
      <div class="eu-banner grid grid-rows-1 grid grid-cols-3 mb-20">
        <p></p>
        <img src="@/assets/eu-banner.png" alt="" style="width: 100%"/>
        <p></p>
      </div>

      <div class="mate-block">
        <div class="prod-content-2">
          <div class="about-main grid grid-rows-1 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2">
            <div class="col-span-1">
              <img class="mate1-img" src="/P7220626.JPG"  alt=""/>
            </div>
            <div class="col-span-1 pad-40 mate-text-test" v-in-viewport.once="{ margin: '-150px 0px' }">
              <h1 class="mate-text" v-html="$t('home.mate1')"></h1>
            </div>
          </div>
        </div>

        <div class="prod-content-2">
          <div class="about-main grid grid-rows-1 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2">
            <div class="col-span-1 pad-40 text-sm mate-text-test" v-in-viewport.once="{ margin: '-150px 0px' }">
              <h1 class="mate-text" v-html="$t('home.mate2')"></h1>
            </div>
            <div class="col-span-1">
              <img class="mate1-img" src="/P7220701.JPG"  alt=""/>
            </div>
          </div>
        </div>

        <div class="prod-content-2">
          <div class="about-main grid grid-rows-1 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2">
            <div class="col-span-1">
              <img class="mate1-img" src="/P7220575.jpg"  alt=""/>
            </div>
            <div class="col-span-1 pad-40" v-in-viewport.once="{ margin: '-150px 0px' }">
              <h1 class="mate-text" v-html="$t('home.mate3')"></h1>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PageImage from '@/components/PageImage.vue'

export default {
  name: 'Home',
  components: {
    PageImage
  }
}
</script>

<style>
.mate1-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.mate-text {
  font-size: 25px;
  text-align: left;
}
.prod-content-2 {
  background-color: #0193de;
  color: white;
}
@media only screen and (max-width: 1023px) {
  .about-main {
    height: auto;
  }
}
@media only screen and (max-width: 675px) {
  .eu-banner {
    margin-bottom: 40px;
  }
  .mate1-img {
    object-fit: contain;
    height: 100%;
    order: 1;
  }
  .mate-text-test {
    order: 2;
  }
  .prod-content-2 {
    display: flex;
    flex-direction: column;
  }
}
</style>